.florist-container {
    overflow: unset;
}

.flo-wireframes-container {
    margin: 0 var(--spacing-medium) 48px;
}

.flo-accessibility-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
}

.flo-accessibility-wrapper {
    margin: 0 auto;
}

.flo-accessibility-container .mini-stepper-list {
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    gap: 48px;
}

.flo-accessibility-container .mini-stepper-list .mini-stepper-item {
    display: flex;
    flex-direction: column;
    max-width: 400px;
}

.flo-accessibility-mini-step {
    display: flex;
    flex-direction: row;
}

.flo-accessibility-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}

.flo-accessibility-header {
    font-size: var(--font-size-medium);
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
}

.flo-accessibility-first-img-wrapper {
    margin-bottom: 24px;
}

@media screen and (max-width: 1380px) {
    .flo-accessibility-container .mini-stepper-list {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 480px) {
    .florist-container .accessibility-portfolio-container  {
        margin-top: -72px;
    }
}
