.work-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 316px;
  height: 293px;
  overflow: hidden;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-color: white;
  box-shadow: 12px 12px 0px var(--color-black);
}

.work-box-container-simple {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 140px;
}

.work-box-container::after {
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.9;
  z-index: 90;
}

.work-box-container:hover {
  box-shadow: none;
  transition: box-shadow 0.1s ease-out;
}

.work-box-container:hover::after {
  opacity: 1;
  transition: opacity 0.1s ease-out;
  background-color: var(--color-black);
}

.work-box-container:hover .work-box-title {
  color: var(--color-pale-background);
}

.work-box-container:hover .work-box-description {
  color: var(--color-pale-background);
}

.work-box-container:hover .work-box-learn {
  color: var(--color-pale-background);
}

.work-box-title {
  position: absolute;
  max-width: 280px;
  margin-top: -80px;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: var(--color-black);
  overflow-wrap: break-word;
  z-index: 100;
  text-align: center;
}

.work-box-container-simple .work-box-title {
  margin-top: 0;
  margin-bottom: 0;
  max-width: 120px;
  font-size: var(--font-size-s);
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 500;
  color: black;
}

.work-box-description {
  position: absolute;
  max-width: 280px;
  margin-top: 24px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-black);
  z-index: 100;
}

.work-box-learn {
  position: absolute;
  margin-top: 100px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: var(--color-black);
  z-index: 100;
}

@media screen and (max-width: 600px) {
  .work-container {
    grid-template-columns: repeat(auto-fit, minmax(130px, 3fr));
    grid-gap: 48px;
  }

  .work-box-container {
    width: 180px;
    height: 160px;
  }

  .work-box-title {
    font-size: 18px;
    max-width: 160px;
    line-height: 22px;
  }

  .work-box-description {
    position: absolute;
    max-width: 140px;
    font-size: 12px;
    line-height: 14px;
  }

  .work-box-learn {
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (max-width: 480px) {
  .work-box-container {
    width: 160px;
    height: 145px;
  }
}
