/* Title includes a Dot (separate component) */
.common-title {
  display: inline-flex;
  align-items: baseline;
  margin: 86px auto 46px;
  font-size: var(--font-size-title-web--m);
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

.common-description {
  width: 100%;
  max-width: 640px;
  margin: 0 auto 48px auto;
  font-size: var(--font-size-medium);
  font-weight: 300;
  line-height: 24px;
}

.common-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 var(--spacing-medium) 64px;
}

.common-subtitle {
  font-size: var(--font-size-xl);
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.common-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 48px;
  justify-items: center;
}

.common-content-wide {
  display: flex;
  flex-direction: column;
  margin-bottom: 72px;
}

.common-before-after-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 96px;
}

.common-mocks-container {
  margin-top: 48px;
}

.common-three-column-container {
  width: 100%;
}

.common-p {
  width: 100%;
  max-width: 640px;
  margin: 0 auto 48px auto;
  font-size: var(--font-size-medium);
  font-weight: 300;
  line-height: 24px;
}

.common-h3 {
  font-size: var(--font-size-l);
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 1302px) {
  .common-title {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .common-description {
    width: 100%;
    max-width: 450px;
  }
}

@media screen and (max-width: 900px) {
  .common-description {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .common-title {
    margin-top: 48px;
    margin-bottom: 24px;
    font-size: var(--font-size-title-web--xs);
  }

  .common-title .dot-container-black {
    height: 9px;
    width: 9px;
  }
}

@media screen and (max-width: 483px) {
  .common-subtitle {
    margin-bottom: 24px;
    font-size: var(--font-size-l);
  }

  .common-description {
    margin-top: 12px;
    font-size: var(--font-size-s) !important;
  }

  .common-mocks-container {
    margin-top: -12px;
  }

  .common-before-after-container {
    margin-top: 48px;
  }
}

@media screen and (max-width: 480px) {
  .common-content {
    grid-template-columns: 1fr;
  }

  .common-content-wide {
    margin-bottom: -48px;
  }

  .common-h3 {
    font-size: var(--font-size-medium);
  }

  .common-p {
    font-size: var(--font-size-s);
  }
}
