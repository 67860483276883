.section-header-container {
  display: flex;
  align-items: flex-end;
  height: 366px;
}

.section-header-mint {
  background-color: var(--color-mint);
}

.section-header-gold {
  background-color: var(--color-gold);
}

.section-header-heading-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: fit-content;
}

.section-header-h2 {
  margin: 0 0 0 24px;
  font-weight: 800;
  font-size: 50px;
  line-height: 40px;
  text-transform: uppercase;
}

.section-header-rectangle {
  margin-left: 474px;
}

.section-header-lines {
  display: flex;
  height: 100%;
}

.section-header-lines .lines-container {
  margin-left: 179px;
}

.section-header-lines .rectangle-black {
  margin-left: 215px;
}

@media screen and (max-width: 1016px) {
  .section-header-rectangle {
    margin-left: 404px;
  }

  .section-header-h2 {
    margin: 0 0 0 12px;
    font-size: var(--font-size-title-web--s);
    line-height: 32px;
  }

  /* dot correction */
  .section-header-heading-container .dot-container {
    height: 12px;
    width: 12px;
    margin-left: 2px;
  }

  /* lines correction */
  .section-header-container .lines-container {
    margin-left: 109px;
  }
}

@media screen and (max-width: 840px) {
  .section-header-h2 {
    font-size: var(--font-size-title-web--xs);
    line-height: 24px;
  }

  .section-header-heading-container .dot-container {
    height: 8px;
    width: 8px;
  }
}

@media screen and (max-width: 806px) {
  #work .section-header-rectangle {
    margin-left: 339px
  }
}

@media screen and (max-width: 740px) {
  #work .section-header-rectangle {
    margin-left: 285px
  }
}

@media screen and (max-width: 700px) {
  .section-header-container {
    /* Reduction of the container size */
    height: 280px;
  }

  #work .section-header-container .rectangle-container {
    /* Margin fix for the selected work section, 
    ** aligns lines with the section above.
    */
    margin-left: 3px;
    height: 280px !important;
  }

  #about .section-header-container .rectangle-container {
    height: 280px !important;
  }

  #contact .section-header-container .rectangle-container {
    height: 280px !important;
  }

  .section-header-container .rectangle-container {
    width: 24px !important;
  }

  .section-header-lines .rectangle-container {
    /* moves lines for about and contact sections */
    margin-left: 120px;
  }
}

@media screen and (max-width: 600px) {
  .section-header-container {
    /* Reduction of the container size */
    height: 200px;
  }

  .section-header-h2 {
    /* Font-size reduction */
    font-size: var(--font-size-title-web--xxs);
    line-height: 21px;
  }

  #about .section-header-container .rectangle-container {
    height: 200px !important;
  }

  #contact .section-header-container .rectangle-container {
    height: 200px !important;
  }

  .section-header-rectangle {
    margin-left: 0;
  }

}

@media screen and (max-width: 563px) {

  /* Correction of the "selected work" 
  ** section. Two lines. Closer dot. 
  */
  #work .section-header-h2 {
    max-width: 45px;
    margin-bottom: -2px;
    line-height: 26px;
  }

  #work .dot-container {
    margin-left: 48px;
  }

  .section-header-lines .rectangle-container {
    /* moves lines for about and contact sections */
    margin-left: 60px;
  }
}

@media screen and (max-width: 480px) {
  #contact .section-header-container .rectangle-container {
    margin-left: 20px;
  }
}

@media screen and (max-width: 460px) {

  /* moves line for "work" */
  #work .section-header-container .rectangle-container {
    margin-left: -21px;
  }

  .section-header-h2 {
    font-size: var(--font-size-title-web--xxxs);
    line-height: 24px;
    margin-bottom: -3px;
  }

  /* "Selected work" correction. 
  ** Significant changes are necessary, header
  ** doesn't fit. Rectangle will align with last
  ** line
  */
  #work .section-header-h2 {
    max-width: 100%;
    margin-bottom: -2px;
    margin-left: 0;
    line-height: 22px;
  }

  #work .dot-container {
    margin-left: 4px;
  }

  #work .section-header-rectangle {
    margin-left: 170px;
  }

  #work .rectangle-container {
    width: 4px !important;
  }

  .section-header-lines .rectangle-container {
    /* moves lines for about and contact sections */
    margin-left: 20px;
  }

  /* lines correction */
  .section-header-container .lines-container {
    margin-left: 85px;
  }
}

@media screen and (max-width: 390px) {

  /* Two lines header for selected work */
  #work .section-header-h2 {
    max-width: 45px;
    margin-bottom: -2px;
    line-height: 23px;
  }

  #work .dot-container {
    margin-left: 36px;
  }

  /* Two lines header for contact 
  #contact .section-header-h2 {
    max-width: 45px;
    margin-bottom: -2px;
    line-height: 23px;
  }

  #contact .dot-container {
    margin-left: -4px;
  }*/

  /* Deleting black rectangle from 
  ** about me and contact me sections
  */

  #about .rectangle-container,
  #contact .rectangle-container {
    display: none;
  }
}

@media screen and (max-width: 355px) {

  #contact .section-header-h2,
  #about .section-header-h2 {
    max-width: 45px;
    margin-bottom: -2px;
    margin-left: 0;
    line-height: 23px;
  }

  #contact .dot-container,
  #about .dot-container {
    margin-left: -4px;
  }
}