.externallink-wrapper-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 48px;
    padding-bottom: 48px;
}

@media screen and (max-width: 483px) {
    .externallink-wrapper-container {
        margin-top: 24px;
        padding-bottom: 0;
    }

    .externallink-wrapper-container .externallink-container {
        margin-top: 0;
    }
}
    