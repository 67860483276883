.about-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: var(--color-white);
}

.about-text-container {
  display: flex;
  flex-direction: row;
  height: 261px;
}

.about-text-container .lines-container {
  display: flex;
  margin-left: 179px;
}

.about-illustration-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  height: 218px;
  padding: 0 43px;
}

.about-illustration-container .lines-container {
  margin-left: 136px;
}

.about-illustration-container .rectangle-black {
  margin-left: 552px;
}

.about-text-illustration {
  display: flex;
  flex-direction: row;
  margin-left: 430px;
}

.about-text-bio-container {
  display: flex;
  flex-direction: row;
}

.about-text-bio {
  width: 314px;
  margin-left: 24px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}

.about-text-bio p:first-of-type {
  margin-top: 0;
}

.about-text-exclamation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 52px;
}

.about-text-exclamation .dot-container {
  margin-top: 21px;
}

.about-end-container {
  display: flex;
  align-items: center;
  height: 98px;
  width: 650px;
  margin-left: 179px;
  background-color: var(--color-gold-pale);
}

.about-end-container p {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 12px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.about-lists {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 3fr));
  max-width: 80%;
  gap: 120px;
  justify-items: center;
  justify-self: center;
  margin: 0 auto;
  padding: 250px 43px 130px 43px;
}

.about-illustration-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 236px;
  margin: 0 43px;
}

.about-illustration-end .rectangle-container:first-of-type {
  margin-left: 431px;
}

@media screen and (max-width: 1232px) {
  .about-lists {
    justify-items: flex-start;
  }
}

@media screen and (max-width: 1185px) {

  /* Makes part of the illustration disappear */
  .about-illustration-container {
    display: none;
  }

  .about-text-bio-container .rectangle-black {
    height: 240px !important;
    margin-top: 35px;
  }

  .about-text-bio-container .rectangle-gold {
    height: 240px !important;
    margin-top: 35px;
  }

  /* upper section ("hey") correction */
  .about-text-bio {
    margin-top: 370px;
    margin-left: -558px;
    width: 560px;
  }

  .about-end-container .lines-container {
    height: 580px;
  }

  .about-lists {
    padding-top: 350px;
  }
}

@media screen and (max-width: 1016px) {

  /* Illustration upper section */
  .about-illustration-container {
    padding: 0;
    margin-left: -27px;
  }

  .about-illustration-container .rectangle-container {
    margin-left: 487px;
  }

  /* Illustration lower section */
  .about-text-container .lines-container {
    margin-left: 109px;
  }

  .about-text-illustration {
    margin-left: 365px;
  }

  .about-end-container {
    margin-left: 109px;
  }

  .about-text-bio {
    margin-left: -490px;
  }

  .about-illustration-end {
    display: none;
  }
}

@media screen and (max-width: 839px) {

  /* Illustration upper section */
  .about-illustration-container .rectangle-container {
    margin-left: 444px;
  }

  /* Illustration lower section */
  .about-text-illustration {
    margin-left: 322px;
  }

  .about-text-bio {
    margin-left: -445px;
  }
}


@media screen and (max-width: 807px) {
  .about-lists {
    justify-items: flex-start;
  }
}

@media screen and (max-width: 767px) {

  /* hide top ilustrations */
  .about-illustration-container .rectangle-black {
    display: none;
  }

  .about-illustration-container {
    height: 100px;
  }

  .about-text-container {
    height: 0;
  }

  .about-text-bio-container .rectangle-gold {
    display: none;
  }

  .about-text-bio-container .rectangle-black {
    display: none;
  }

  .about-text-bio {
    margin-top: 134px;
    margin-left: -315px;
    width: 220%;
  }

  .about-end-container {
    height: 74px;
    width: 80%;
    margin-top: 48px;
  }

  .about-end-container .abut-end-p {
    margin-left: 93px;
    padding-right: 12px;
    font-size: var(--font-size-medium);
  }

  .about-end-container .lines-container {
    position: absolute;
    margin-top: 160px;
    height: 440px;
  }

  .about-lists {
    padding-top: 340px;
    gap: 60px;
  }
}

@media screen and (max-width: 700px) {

  /* Illustration upper section */
  .about-illustration-container .rectangle-container {
    margin-left: 320px;
  }

  /* Illustration lower section */
  .about-text-illustration {
    margin-left: 198px;
  }

  /* Corrects bio position */
  .about-text-bio {
    margin-left: -194px;
    width: 140%;
  }

  .about-end-container .lines-container {
    height: 480px;
  }
}

@media screen and (max-width: 656px) {
  .about-text-bio {
    width: 160%;
    margin-left: -194px;
  }

  .about-end-container .lines-container {
    margin-top: 200px;
    height: 560px;
  }

  .about-lists {
    padding-top: 400px;
  }
}

@media screen and (max-width: 600px) {
  .section-header-lines .rectangle-black {
    height: 200px !important;
  }

  .about-end-container .lines-container {
    height: 620px;
  }

  .about-lists {
    padding-top: 460px;
  }
}

@media screen and (max-width: 563px) {

  /* Illustration upper section */
  .about-illustration-container .rectangle-container {
    margin-left: 240px;
  }

  /* Bio header fix */
  .about-end-container {
    width: 60%;
  }

  .about-end-container .abut-end-p {
    font-size: 14px;
    line-height: 16px;
  }

  /* Bio text fix */
  .about-text-bio {
    position: absolute;
    width: 43%;
    margin-left: -255px;
    font-size: var(--font-size-s);
  }
}

@media screen and (max-width: 460px) {

  /* lines fix */
  .about-end-container {
    margin-left: 85px;
  }

  /* Bio header fix */
  .about-end-container {
    width: 75%;
  }

  /* Bio text fix */
  .about-text-bio {
    width: 50%;
    margin-left: unset;
    left: 180px;
  }
}

/*
@media screen and (max-width: 475px) {
  .about-illustration-container {
    display: flex;
    justify-content: space-between;
  }

  .about-illustration-container .rectangle-container {
    margin-left: 40px;
  }

  .about-illustration-container .lines-container {
    margin-left: 136px;
  }
}




@media screen and (max-width: 460px) {
  .section-header-lines .rectangle-container {
    height: 297px !important;
  }

  .about-illustration-container {
    height: 140px;
  }

  .about-illustration-container .lines-container {
    margin-left: 112px;
  }

  .about-illustration-container .rectangle-black {
    display: none;
  }

  .about-text-container {
    height: 0;
  }

  .about-text-container .lines-container {
    margin-left: 85px;
  }

  .about-text-bio-container .rectangle-gold {
    display: none;
  }

  .about-text-bio-container .rectangle-black {
    display: none;
  }

  .about-text-bio {
    position: absolute;
    top: 1820px;
    margin-left: -100px;
    width: 50%;
  }

  .about-end-container .lines-container {
    margin-left: -94px;
  }

  .about-text-exclamation {
    display: none;
  }

  .about-end-container {
    width: calc(100% - 85px);
    margin-left: 85px;
    margin-top: -20px;
  }

  .about-end-container .lines-container {
    position: absolute;
    margin-left: 0px;
    height: 800px;
  }

  .about-end-container .abut-end-p {
    width: 50%;
    margin-left: 100px;
    font-size: var(--font-size-s);
    line-height: 16px;
  }

  .about-lists {
    margin-top: 140px;
  }
}

@media screen and (max-width: 408px) {
  .about-illustration-container .rectangle-container {
    display: none;
  }
}

@media screen and (max-width: 393px) {
  .about-text-bio {
    top: 2450px;
  }

  .about-end-container .lines-container {
    height: 860px;
  }

  .about-lists {
    margin-top: 180px;
  }
}
*/