.hero-lines-container-top {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 420px;
}

.hero-lines-container-top .lines-container {
  margin-left: 131px;
}

.hero-lines-rectangle-single {
  margin-left: 117px;
}

.hero-copy-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 126px;
}

.hero-copy-title {
  margin-left: 128px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: var(--font-size-title);
  line-height: 84px;
}

.hero-copy-name {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 43px;
}

.hero-copy-illustration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 24px;
}

.hero-copy-illustration .rectangle-container:first-of-type {
  margin-bottom: 12px;
}

.hero-copy-illustration .rectangle-container:last-of-type {
  margin-top: 12px;
}

.hero-lines-rectangles-small {
  display: flex;
  height: 250px;
}

.hero-lines-rectangles-big {
  display: flex;
  align-items: flex-end;
  height: 420px;
  margin-left: 167px;
}

.hero-lines-rectangles-big .rectangle-container:last-of-type {
  margin-left: -26px;
  z-index: -1;
}

.hero-tagline-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 196px;
}

.hero-tagline-illustrations {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.hero-tagline-illustrations .lines-container {
  margin-left: 131px;
}

.hero-tagline-illustrations-two {
  display: flex;
  margin-left: 215px;
}

.hero-tagline-illustrations-two .rectangle-mint {
  margin-left: -26px;
  z-index: -1;
}

.hero-tagline-illustrations-three {
  height: 100%;
  margin-left: 121px;
}

.hero-tagline-headline {
  margin-left: 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.hero-end-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 196px;
}

.hero-end-container .lines-container {
  margin-left: 131px;
}

.hero-end-container .rectangle-black {
  margin-left: 215px;
}

@media screen and (max-width: 1205px) {
  .hero-lines-container-top {
    height: 220px;
  }

  .hero-copy-title {
    font-size: var(--font-size-title-web--xl);
  }

  .hero-copy-name {
    font-size: 24px;
  }

  .hero-tagline-headline {
    font-size: var(--font-size-medium);
    margin-left: 28px;
  }

  .hero-tagline-illustrations-three {
    margin-left: 80.5px;
  }

  .hero-lines-rectangle-single {
    margin-left: 76.5px;
  }
}

@media screen and (max-width: 1065px) {
  .hero-tagline-headline {
    max-width: 220px;
    margin-top: -6px;
  }
}

@media screen and (max-width: 1016px) {
  .hero-lines-container-top {
    height: 227px;
  }

  .hero-lines-container-top .lines-container {
    height: 420px;
    margin-left: 61px;
  }

  .hero-tagline-illustrations .lines-container {
    margin-left: 61px;
  }

  .hero-end-container .lines-container {
    margin-left: 61px;
  }

  .hero-lines-container-top {
    height: 220px;
  }

  .hero-copy-container {
    height: 106px;
  }

  .hero-copy-title {
    margin: 16px 0 16px 57px;
    font-size: var(--font-size-title-web--l);
  }

  .hero-copy-name {
    font-size: var(--font-size-medium);
  }

  .hero-tagline-illustrations-three {
    margin-left: 39.5px;
  }

  .hero-lines-rectangle-single {
    margin-left: 36.5px;
  }

  .hero-lines-rectangle-single {
    margin-left: 35.5px;
  }

  /* Dot correction */
  .hero-copy-illustration .rectangle-container {
    height: 32px !important;
  }

}

@media screen and (max-width: 806px) {

  /* Title size */
  .hero-copy-title {
    font-size: var(--font-size-title-web--m);
  }

  /* Illustrations corrections */
  .hero-lines-rectangles-big {
    margin-left: 100px;
  }

  .hero-tagline-illustrations-two {
    margin-left: 150px;
  }

  .hero-tagline-illustrations-three {
    margin-left: 37.5px;
  }

  /* Bottom correction */

  .hero-end-container {
    height: 40px;
  }

  .hero-end-container .rectangle-container {
    height: 40px !important;
    margin-left: 150px;
  }
}

@media screen and (max-width: 740px) {

  /* Header size correction */
  .hero-lines-container-top {
    height: 180px;
  }

  /* Title size */
  .hero-copy-title {
    font-size: var(--font-size-title-web--s);
  }

  /* Illustrations corrections */
  .hero-lines-rectangles-big {
    margin-left: 46px;
  }

  .hero-tagline-illustrations-two {
    margin-left: 96px;
  }

  .hero-tagline-illustrations-three {
    margin-left: 37.5px;
  }

  /* Bottom correction */
  .hero-end-container .rectangle-container {
    margin-left: 96px;
  }
}

@media screen and (max-width: 700px) {
  .hero-lines-container-top {
    height: 206px;
  }

  .hero-lines-container-top .lines-container {
    height: 420px;
    margin-left: 61px;
  }

  .hero-tagline-illustrations .lines-container .hero-lines-rectangles-big {
    height: 420px;
    margin-left: 76px;
  }

  .hero-lines-rectangles-big .rectangle-black {
    width: 24px !important;
    height: 168px !important;
  }

  .hero-lines-rectangles-big .rectangle-mint {
    width: 24px !important;
    margin-left: -12px !important;
  }

  .hero-copy-container {
    height: fit-content;
  }

  .hero-lines-rectangle-single {
    display: none;
  }

  /* Copy Section */

  .hero-copy-container {
    height: 64px;
  }

  .hero-copy-title {
    margin-left: 59px;
    font-size: 40px;
    line-height: 44px;
  }

  .hero-copy-name {
    position: absolute;
    top: 200px;
    right: -48px;
    font-size: 18px;
    line-height: 22px;
    transform: rotate(-90deg);
  }

  .hero-copy-name::after {
    position: absolute;
    content: "";
    top: 6px;
    right: 235px;
    width: 10px;
    height: 10px;
    background: var(--color-mint);
    border-radius: 50%;
    ;
  }

  .hero-copy-illustration {
    display: none;
  }

  /* Tagline Section */

  .hero-tagline-container {
    height: 280px;
  }

  .hero-tagline-illustrations {
    align-items: flex-start;
  }

  .hero-tagline-illustrations .lines-container {
    margin-left: 61px;
  }

  .hero-tagline-illustrations-two {
    margin-left: 99px;
  }

  .hero-tagline-illustrations-two .rectangle-black {
    width: 24px !important;
    height: 280px !important;
  }

  .hero-tagline-illustrations-two .rectangle-mint {
    width: 24px !important;
    height: 70px !important;
    margin-left: -12px !important;
  }

  .hero-tagline-illustrations-three {
    display: none;
  }

  .hero-tagline-headline {
    position: absolute;
    display: flex;
    max-height: 400px;
    top: 504px;
    right: -48px;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    transform: rotate(-90deg);
  }

  /* end section */
  .hero-end-container {
    height: 12px;
  }

  .hero-end-container .lines-container {
    margin-left: 61px;
  }

  .hero-end-container .rectangle-black {
    width: 24px !important;
    height: 12px !important;
    margin-left: 99px !important;
  }
}

@media screen and (max-width: 460px) {
  .hero-copy-title {
    font-size: var(--font-size-title-web--xs);
  }

  .hero-lines-rectangles-small {
    display: none;
  }

  .hero-lines-rectangles-big {
    margin-left: 98px;
  }
}

@media screen and (max-width: 370px) {
  .hero-copy-title {
    font-size: var(--font-size-title-web--xxs);
  }

  .hero-lines-rectangles-big {
    margin-left: 46px;
  }

  .hero-tagline-illustrations-two {
    margin-left: 46px;
  }

  .hero-end-container {
    display: none;
  }
}