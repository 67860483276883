.visual-design-container {
    background-color: var(--color-pale-background);
}

.visual-design-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 var(--spacing-medium);
    padding-bottom: 48px;
}

.visual-design-inspired-description {
    display: flex;
    width: 100%;
    max-width: 640px;
    font-size: var(--font-size-medium);
    line-height: 24px;
    font-weight: 300;
    white-space: pre-line;
}

.visual-design-colors-primary {
    margin-top: 60px;
    margin-bottom: 24px;
}

.visual-design-colors-primary-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 48px;
    margin-top: 60px;
    margin-bottom: 60px;
    padding-left: 0;
}

.visual-design-colors-primary {
    list-style: none;
    padding: 0;
}

.visual-design-colors-primary li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 32px 32px 0;
}

.visual-design-type-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 96px;
    padding-bottom: 48px;
}

.visual-design-type-img {
    width: 100%;
    max-width: 320px;
}

.visual-design-type-h3 {
    margin-bottom: 32px;
    font-size: var(--font-size-s);
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}

.visual-design-complex-container {
    display: grid;
    grid-template-columns: auto-fit(1fr 1fr);
    gap: 16px 72px;
}

.visual-design-type-h2 {
    grid-column: 1 / 3;
    font-size: var(--font-size-s);
    text-transform: uppercase;
    text-align: center;
}

/* Complex colors styles */

.visual-design-complex-colors-container {
    margin-bottom: 100px;
}

.visual-design-complex-colors-title {
    margin-bottom: 48px;
    font-size: var(--font-size-l);
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}

.visual-design-complex-colors-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding-left: 0;
}

.visual-design-complex-colors-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    gap: 12px;
}

.visual-design-complex-colors-h4 {
    font-size: var(--font-size-medium);
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}

.visual-design-complex-colors-pair-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    gap: 60px;
}

.visual-design-complex-colors-pair-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    margin: 0 !important;
}

li[style="background-color: rgb(255, 249, 246); color: rgb(36, 31, 29); height: 300px; width: 300px;"] {
    border: 1px solid #D6D0CE;
}

@media screen and (max-width: 1256px) {
    .visual-design-colors-primary-list {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }

    .visual-design-colors-primary li { 
        display: flex;
        margin-right: 0;
    }
}

@media screen and (max-width: 830px) { 
    .visual-design-complex-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px 72px;
    }

    .visual-design-type-h2 {
        grid-column: 1;
        font-size: var(--font-size-s);
        text-transform: uppercase;
        text-align: center;
    }
}

@media screen and (max-width: 483px) {
    .visual-design-type-container {
        gap: 60px;
    }

    .visual-design-colors-primary {
        margin-top: 24px;
        margin-bottom: -24px;
    }

    .visual-design-complex-colors-pair-list {
        gap: 48px 24px;
    }
}