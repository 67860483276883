.list-container {
  width: fit-content;
  max-width: 283px;
}

.list-ul {
  list-style: none;
  border-left: 12px solid var(--color-black);
  padding: 0;
}

.list-title {
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: var(--color-black);
  text-transform: uppercase;
}

.list-ul li {
  margin-left: 32px;
  margin-bottom: 32px;
}

.list-complex-dates {
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-black);
}

.list-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-black);
}

.list-content-simple {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-black);
}
