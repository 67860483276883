.overview-portfolio-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 var(--spacing-medium) 64px;
}

.overview-portfolio-title {
  display: inline-flex;
  align-items: baseline;
  margin: 86px auto 46px;
  font-size: var(--font-size-title-web--m);
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

.overview-portfolio-shortlist {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0;
  margin: 0 auto;
}

.overview-portfolio-item {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-right: 48px;
}

.overview-portfolio-item:last-of-type {
  margin-right: 0;
}

.overview-portfolio-item-title {
  font-weight: 500;
  font-size: var(--font-size-l);
  line-height: 24px;
  text-transform: uppercase;
}

.overview-portfolio-item-description {
  margin-left: 6px;
  font-size: var(--font-size-l);
  font-weight: 300;
  line-height: 24px;
  text-transform: uppercase;
}

.overview-portfolio-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 3fr));
  grid-gap: 24px;
  padding-left: 0;
  list-style-type: none;
}

.overview-portfolio-details-item {
  display: flex;
  flex-direction: column;
  justify-self: center;
  max-width: 340px;
}

.overview-portfolio-details-title {
  font-size: var(--font-size-xl);
  font-weight: 700;
  text-transform: uppercase;
}

.overview-portfolio-details-description {
  border-left: 12px solid black;
  padding-left: 24px;
  padding-top: 12px;
  height: 200px;
  font-size: var(--font-size-medium);
  line-height: 24px;
  font-weight: 300;
}

@media screen and (max-width: 1302px) {
  .overview-portfolio-title {
    margin-bottom: 32px;
  }

  .overview-portfolio-shortlist {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .overview-portfolio-item {
    margin-right: 0;
  }

  .overview-portfolio-item-title,
  .overview-portfolio-item-description {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 580px) {
  .overview-portfolio-shortlist {
    margin-bottom: 12px;
  }

  .overview-portfolio-item-title,
  .overview-portfolio-item-description {
    font-size: var(--font-size-m);
    margin-top: 9px;
    margin-bottom: 9px;
  }
}

@media screen and (max-width: 540px) {
  .overview-portfolio-title {
    margin-top: 48px;
    margin-bottom: 24px;
    font-size: var(--font-size-title-web--xs);
  }

  .overview-portfolio-title .dot-container-black {
    height: 9px;
    width: 9px;
  }
}

@media screen and (max-width: 483px) {
  .overview-portfolio-shortlist {
    margin-bottom: 0;
  }

  .overview-portfolio-item-title,
  .overview-portfolio-item-description {
    font-size: var(--font-size-s);
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .overview-portfolio-details-title {
    font-size: var(--font-size-l);
  }

  .overview-portfolio-details-description {
    height: 150px;
    font-size: var(--font-size-s);
  }
}

@media screen and (max-width: 400px) {
  .overview-portfolio-content {
    margin: 0 var(--spacing-s);
  }

  .overview-portfolio-shortlist {
    align-items: flex-start;
  }

  .overview-portfolio-details {
    grid-template-columns: repeat(auto-fit, minmax(200px, 3fr));
  }

  .overview-portfolio-details-item {
    max-width: 280px;
  }
}
