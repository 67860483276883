.rectangle-container {
    height: 100%;
}

.rectangle-black {
    background-color: var(--color-black);
}

.rectangle-mint {
    background-color: var(--color-mint);
}

.rectangle-gold {
    background-color: var(--color-gold);
}