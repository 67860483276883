.wide-content-container {
  display: flex;
  flex-direction: column;
  width: 450px;
  min-height: 420px;
  margin: 0 auto;
}

.wide-content-text-container {
  min-height: 180px;
}

.wide-content-title {
  font-size: var(--font-size-l);
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.wide-content-text {
  max-width: 450px;
  font-size: var(--font-size-medium);
  font-weight: 300;
  line-height: 24px;
}

.wide-content-image {
  width: 450px;
  cursor: zoom-in;
}

@media screen and (max-width: 590px) {
  .wide-content-container {
    width: 100%;
  }

  .wide-content-image {
    width: 100%;
  }
}

@media screen and (max-width: 483px) {
  .bigstepper-description {
    width: 100%;
    max-width: 330px;
  }

  .wide-content-container {
    min-height: fit-content;
  }

  .wide-content-title {
    font-size: var(--font-size-medium);
  }

  .wide-content-text {
    font-size: var(--font-size-s);
  }

  .wide-content-text-container {
    min-height: unset;
  }
}
