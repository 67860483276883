.footer-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 236px;
  margin-left: calc(55% - 189px);
}

.footer-content-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  width: 475px;
  height: 100%;
}

.footer-content-container::before {
  content: "";
  display: flex;
  position: absolute;
  margin: -190px auto 12px;
  width: 438px;
  height: 12px;
  background-color: var(--color-black);
}

.footer-copy {
  display: flex;
  position: absolute;
  margin-top: 60px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-size: var(--font-size-s);
  text-align: center;
}

.footer-social-buttons {
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 102px;
  margin-top: -100px;
}

.footer-copy .dot-container {
  margin-top: 21px;
}

.commmon-portfolio-container .footer-container {
  margin-left: 0;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .footer-content-container::before {
    width: 280px;
  }
}

@media screen and (max-width: 650px) {
  .footer-content-container::before {
    width: 180px;
  }
}

@media screen and (max-width: 515px) {
  .footer-container {
    margin-left: calc(55% - 179px);
  }
}

@media screen and (max-width: 480px) {
  .footer-container {
    height: 210px;
    margin-left: calc(55% - 169px);
  }
}

@media screen and (max-width: 452px) {
  .footer-container {
    margin-left: calc(55% - 169px);
  }
}

@media screen and (max-width: 440px) {
  .footer-container {
    margin-left: unset;
    margin-top: 40px;
  }
}
