.dot-container {
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.dot-container-black {
    background-color: var(--color-black);
}

.dot-container-mint {
    background-color: var(--color-mint);
}

.dot-container-gold {
    background-color: var(--color-gold);
}