.mini-stepper-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;
    gap: 48px;
    justify-self: center;
    padding-left: 0;
}

.mini-stepper-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 300px;
}

.mini-stepper-numbers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    min-height: 32px;
    width: 32px;
    min-width: 32px;
    margin-top: 4px;
    margin-right: 12px;
    border-radius: 50%;
    background-color: var(--color-pale-background);
    color: var(--color-dark-grey);
    font-size: var(--font-size-medium);
}

.mini-stepper-numbers--pink {
    background-color: var(--color-pink);
    color: #FBF9F7;
}

.mini-stepper-numbers--blue {
    background-color: var(--color-blue);
    color: #FBF9F7;
}

.mini-stepper-numbers--red {
    background-color: var(--color-red);
    color: #FBF9F7;
}

.mini-stepper-numbers--red-dark {
    background-color: var(--color-red-dark);
    color: #FBF9F7;
}

.mini-stepper-description {
    margin-top: 0;
    font-size: var(--font-size-medium);
    font-weight: 300;
    line-height: 24px;
}

@media screen and (max-width: 1370px) {
    .mini-stepper-list {
        gap: 24px;
    }
    
    .mini-stepper-item {
        max-width: 260px;
    }
}

@media screen and (max-width: 980px) {
    .mini-stepper-list {
        flex-direction: column;
    }
}