.before-after-container {
    display: flex;
    flex-wrap: wrap;  
    gap: 48px;
}

.before-after-image {
    width: 230px;
}

.before-after-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

@media screen and (max-width: 480px) {
    .before-after-container {
        gap: 12px;
        margin-bottom: -24px;
    }
}