.accessibility-h1 {
  display: inline-flex;
  align-items: baseline;
  margin: 0 auto 46px;
  font-size: var(--font-size-title-web--m);
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

.accessibility-portfolio-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 var(--spacing-medium) 64px;
}

.accessibility-stepper-container {
  margin: 0 auto;
}

@media screen and (max-width: 1302px) {
  .accessibility-h1 {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 540px) {
  .accessibility-h1 {
    margin-top: 48px;
    margin-bottom: 24px;
    font-size: var(--font-size-title-web--xs);
  }

  .accessibility-h1 .dot-container-black {
    height: 9px;
    width: 9px;
  }

  .accessibility-portfolio-container {
    margin-top: 48px;
  }

  .accessibility-portfolio-content {
    margin-bottom: 24px;
  }
}