.three-column-mixed-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    flex-wrap: wrap;
}

.three-column-item {
    margin: 0 auto;
}

.three-column-h3 {
    font-size: var(--font-size-s);
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
}

.three-column-img--desktop {
    width: 100%;
    max-width: 450px;
}

.three-column-img--tablet {
    width: 100%;
    max-width: 320px;
}

.three-column-img--mobile {
    width: 100%;
    max-width: 213px;
}
