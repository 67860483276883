.wide-two-column-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding-bottom: 48px;
}

@media screen and (max-width: 480px) {
    .wide-two-column-container {
        padding-bottom: 0;
    }
}