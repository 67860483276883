/* Font import */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75,383;0,75,800;0,100,300;0,100,400;0,100,500;0,100,600;0,100,700;0,100,800;1,100,400&display=swap");

:root {
  /* Colors */
  --color-black: #241f1d;
  --color-white: #fbf9f7;
  --color-mint: #9ab7c1;
  --color-gold: #eac885;
  --color-gold-pale: #f7e9ce;
  --color-red: #c8553d;
  --color-red-dark: #C1121F;
  --color-pale-background: #FBF9F7;
  --color-pink: #CD8296;
  --color-blue: #216DDF;
  /* Font sizes */
  --font-size-xl: 26px;
  --font-size-l: 20px;
  --font-size-medium: 16px;
  --font-size-s: 12px;
  --font-size-title: 70px;
  --font-size-title-web--xl: 64px;
  --font-size-title-web--l: 58px;
  --font-size-title-web--m: 48px;
  --font-size-title-web--s: 40px;
  --font-size-title-web--xs: 32px;
  --font-size-title-web--xxs: 28px;
  --font-size-title-web--xxxs: 24px;
  /* Spacing */
  --spacing-medium: 48px;
  --spacing-small: 24px;
}


/* Filter spec from: https://codepen.io/sosuke/pen/Pjoqqp 
** Icons should be filtered from black
*/
