.narrow-content-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
}

.narrow-content-column-title {
    font-size: var(--font-size-l);
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
}

.narrow-content-column-description {
    margin-top: 0;
    font-size: var(--font-size-medium);
    font-weight: 300;
    line-height: 24px;
}

.narrow-content-column-image {
    max-width: 215px;
    justify-self: flex-start;
    align-self: center;
}

@media screen and (max-width: 507px) {
    .before-after-container {
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width: 480px) {
    .narrow-content-column-title {
        font-size: var(--font-size-medium);
    }

    .narrow-content-column-description {
        width: 100%;
        max-width: 100%;
        font-size: var(--font-size-s);
    }

    .mini-stepper-description {
        font-size: var(--font-size-s) !important;
        line-height: 20px;
    }
}