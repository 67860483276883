html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  /* margin: 0 48px; */
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}