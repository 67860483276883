.lines-container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.line {
  margin-right: 12px;
  width: 4px;
  height: 100%;
  content: " ";
  background-color: var(--color-black);
}
