.hero-portfolio-container {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    padding-top: 20px;
    padding-bottom: 24px;
    background-color: var(--color-pale-background);
}

.hero-portfolio-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 var(--spacing-medium);
}

.hero-portfolio-title {
    margin-bottom: 0;
    font-size: var(--font-size-title);
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
}

.hero-portfolio-description {
    display: flex;
    width: 484px;
    margin: 20px auto 0;
    font-size: var(--font-size-l);
    font-weight: 400;
    line-height: 30px;
}

.hero-portfolio-image--mobile {
    width: 281px;
}

.hero-portfolio-image--web {
    width: 80%;
}

.hero-portfolio-image-container {
    display: flex;
    justify-content: center;
    margin: 48px auto 24px;
}

@media screen and (max-width: 640px) {
    .hero-portfolio-container {
        margin-top: -24px;
    }

    .hero-portfolio-title {
        font-size: var(--font-size-title-web--s);
    }

    .hero-portfolio-description {
        width: 380px;
        font-size: var(--font-size-medium);
        line-height: 24px;
    } 
}

@media screen and (max-width: 470px) {
    .hero-portfolio-description {
        width: 100%;
        font-size: var(--font-size-medium);
        line-height: 24px;
    } 

    .hero-portfolio-image--web {
        width: 120%;
    }
}

@media screen and (max-width: 400px) { 
    .hero-portfolio-content { 
        margin: 0 var(--spacing-small); 
    }
}