.lofi-portfolio-process-container {
  display: flex;
  flex-direction: column;
}

.lofi-portfolio-content {
  margin: 0 var(--spacing-medium);
}

.lofi-portfolio-process-container .overview-portfolio-title {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.mocks-portfolio--pink {
  background-color: var(--color-pale-background);
}

@media screen and (max-width: 480px) {
  .flo-lo-fi-subtitle {
    font-size: var(--font-size-l);
  }
}
