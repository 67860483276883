.final-design-content {
  display: flex;
  flex-direction: column;
}

.final-design-img {
  max-width: 100%;
  object-fit: cover;
  margin: -24px auto 0;
  cursor: zoom-in;
}

.final-design-img-mobile {
  display: none;
  max-width: 100%;
  margin: -24px auto 0;
  cursor: zoom-in;
}

.yarl__navigation_prev,
.yarl__navigation_next {
  display: none;
}

@media screen and (max-width: 1200px) {
  .final-design-img {
    display: none;
  }

  .final-design-img-mobile {
    display: flex;
  }
}
