.navlink-container {
  display: inline-flex;
  margin-right: 24px;
  color: var(--color-black);
  font-size: var(--font-size-medium);
  text-transform: uppercase;
}

.navlink-container:last-of-type {
  margin-right: 0;
}

.navlink-container > span {
  padding: 4px 4px;
  font-weight: 500;
  text-decoration: none;
  color: var(--color-black);
  border-bottom: 3px solid transparent;
  pointer-events: auto;
  cursor: pointer !important;
  transition: all 0.01s ease-in;
}

.navlink-container > span:hover {
  border-bottom: 3px solid var(--color-black);
  color: var(--color-black) !important;
  font-weight: 800 !important;
}

.active {
  border-bottom: 3px solid var(--color-black) !important;
  color: var(--color-black) !important;
  font-weight: 800 !important;
}
