.research-portfolio-container {
    background-color: var(--color-pale-background);
}

.research-portfolio-content {
    display: flex;
    flex-direction: column;
    margin: 0 var(--spacing-medium);
}

.research-portfolio-process-container {
    display: flex;
    flex-direction: column;
}

.research-portfolio-subtitle {
    font-size: var(--font-size-xl);
    font-weight: 700;
    text-transform: uppercase;
}

.research-portfolio-process-container .research-portfolio-subtitle {
    text-align: center;
}

.research-portfolio-process-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.research-portfolio-process-item {
    margin-right: 48px;
    font-size: var(--font-size-l);
    font-weight: 500;
    text-transform: uppercase;
}

.research-portfolio-process-item:last-of-type {
    margin-right: 0;
}

.research-portfolio-process-description {
    width: 640px;
    margin: 24px auto;
    font-size: var(--font-size-medium);
    font-weight: 300;
    line-height: 24px;
}

.research-portfolio-two-sections-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 60px;
}

.research-portfolio-problem-container,
.research-portfolio-pain-points-content {
    margin-top: 40px;
}

.research-portfolio-problem-header {
    font-size: var(--font-size-l);
    font-weight: 500;
    text-transform: uppercase;
}

.research-portfolio-problem-statement {
    font-size: var(--font-size-medium);
    font-weight: 300;
    line-height: 24px;
}

.research-portfolio-persona-basics-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.research-portfolio-persona-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 48px;
}

.research-portfolio-persona-quote {
    max-width: 350px;
    font-size: var(--font-size-medium);
    font-weight: 300;
    line-height: 24px;
}

.research-portfolio-persona-lists {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
}

.research-portfolio-persona-lists li {
    margin-bottom: 3px;
    font-size: var(--font-size-medium);
    font-weight: 300;
    line-height: 24px;
}

.research-portfolio-persona-h4 {
    font-size: var(--font-size-medium);
    font-weight: 500;
    text-transform: uppercase;
}

.research-portfolio-persona-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
    list-style: none;
    padding-left: 0;
}

.research-portfolio-persona-details span {
    display: inline-flex;
}

.research-portfolio-persona-item-title {
    margin: 0 6px 0 0;
    font-size: var(--font-size-medium);
    font-weight: 500;
}

.research-portfolio-persona-item-description {
    margin: 0;
    text-transform: capitalize;
}

.research-portfolio-persona-goals,
.research-portfolio-persona-frustrations {
    width: 200px;
    min-width: 200px;
    padding-left: 0;
}

.research-portfolio-pain-points-description {
    margin-top: 0;
    font-size: var(--font-size-medium);
    font-weight: 300;
    line-height: 24px;
}

.research-portfolio-pain-points-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 24px;
    list-style: none;
    padding-left: 0;
}

.research-portfolio-pain-points-item {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}

.research-portfolio-pain-points-item--title {
    display: inline-flex;
    font-size: var(--font-size-medium);
    font-weight: 500;
    text-transform: uppercase;
}

.research-portfolio-pain-points-item--desc {
    font-size: var(--font-size-medium);
    font-weight: 300;
    line-height: 24px;
}

.research-portfolio-pain-numbers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    min-height: 32px;
    width: 32px;
    min-width: 32px;
    margin-right: 12px;
    border: 1px solid var(--color-dark-grey);
    border-radius: 50%;
    font-size: var(--font-size-medium);
}

.research-portfolio-pain-numbers--pink {
    background-color: var(--color-pink);
    color: #FBF9F7;
}

.research-portfolio-pain-numbers--blue {
    background-color: var(--color-blue);
    color: #FBF9F7;
}

.research-portfolio--competitive {
    margin-top: 72px !important;
}

.research-portfolio--competitive .research-portfolio-subtitle {
    margin-bottom: 44px;
}

@media screen and (max-width: 984px) {
    .research-portfolio-process-list li {
        font-size: var(--font-size-medium);
    }
}

@media screen and (max-width: 853px) {
    .research-portfolio-process-list {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        margin-bottom: 0;
    }

    .research-portfolio-process-list li {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .research-portfolio-process-description {
        width: 100%;
    }

    .research-portfolio-persona-basics-container {
        align-items: center;
    }
}

@media screen and (max-width: 511px) {
    .research-portfolio-persona-lists ul {
        min-width: 200px;
        width: 200px;
        justify-self: center;
        margin-bottom: 0;
    }

}

@media screen and (max-width: 483px) {
    .research-portfolio-subtitle {
        font-size: var(--font-size-l);
    }

    .research-portfolio-process-list li {
        font-size: var(--font-size-s);
    }

    .research-portfolio-process-description {
        margin-top: 12px;
        font-size: var(--font-size-s);
    }

    .research-portfolio-problem-header {
        font-size: var(--font-size-medium);
    }

    .research-portfolio-problem-statement {
        font-size: var(--font-size-s);
    }

    .research-portfolio-persona-image {
        margin-right: 24px;
    }

    .research-portfolio-persona-quote {
        font-size: var(--font-size-s);
    }

    .research-portfolio-persona-h4 {
        font-size: var(--font-size-s);
    }

    .research-portfolio-persona-item {
        font-size: var(--font-size-s) !important;
    }

    .research-portfolio-persona-item span>h5,
    span {
        font-size: var(--font-size-s);
    }

    .research-portfolio-persona-lists {
        margin: 0 auto;
    }

    .research-portfolio-pain-points-description {
        font-size: var(--font-size-s);
    }

    .research-portfolio-pain-points-item span {
        font-size: var(--font-size-s);
        line-height: 20px;
    }
}