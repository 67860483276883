.work-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(316px, 3fr));
  grid-gap: 140px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding: 100px 43px;
  background-color: var(--color-white);
}

.work-cta-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-bottom: 80px;
  background-color: var(--color-white);
}

.work-illustration-container {
  background-color: var(--color-white);
  padding: 0 43px;
}

.work-illustration-container .rectangle-black {
  margin-left: 431px;
}

@media screen and (max-width: 1116px) {
  .work-illustration-container {
    display: none;
  }
}

@media screen and (max-width: 600px) { 
  .work-container {
    grid-gap: 72px;
  }
}