.contact-container {
  padding-bottom: 120px;
}

.contact-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 275px;
}

.contact-container .lines-container {
  position: absolute;
  margin-left: 179px;
  height: 631px;
}

.contact-text-claim {
  margin-right: 12px;
  font-weight: 300;
  font-size: 26px;
  line-height: 31px;
}

.contact-text-block {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  margin-left: 238px;
}

.contact-text-first-line-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.contact-exclamation-mark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
}

.contact-exclamation-mark .dot-container {
  margin-top: 21px;
}

.contact-email-download-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-bottom: -63px;
  margin-left: 12px;
}

.contact-email::after {
  content: "wysockatreder@gmail.com";
  display: flex;
  width: fit-content;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-black);
}

.contact-button-container {
  display: flex;
  width: 100%;
}

.contact-container .lines-container {
  position: absolute;
  margin-left: 179px;
}

.contact-button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 42px;
  margin-top: 24px;
}

@media screen and (max-width: 1075px) {
  .contact-text-block {
    flex-direction: column;
    margin-left: 505px;
    align-items: baseline;
  }

  .contact-email::after {
    margin-top: 12px;
    margin-left: 0;
  }

  .contact-email-download-container {
    margin-left: 0;
  }

  .contact-button-wrapper {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1016px) {
  .contact-container .lines-container {
    margin-left: 109px;
  }

  .contact-text-block {
    margin-left: 357px;
  }
}

@media screen and (max-width: 840px) {
  .contact-text-block {
    margin-left: 304px;
  }
}

@media screen and (max-width: 700px) {
  .contact-text-block {
    margin-left: 315px;
  }

  .contact-text-claim {
    font-size: var(--font-size-medium);
    margin-top: 3px;
  }

  .contact-email::after {
    font-size: var(--font-size-s);
    margin-top: 3px;
  }

  .contact-button-wrapper {
    margin-top: 16px;
  }
}

@media screen and (max-width: 600px) {
  .contact-text-block {
    margin-left: 292px;
  }
}

@media screen and (max-width: 563px) {
  .contact-text-block {
    margin-left: 232px;
  }
}

@media screen and (max-width: 480px) {
  .contact-exclamation-mark {
    display: none;
  }

  .contact-text-container {
    height: 100px;
  }

  .contact-text-block {
    margin-left: 212px;
  }

  .contact-container .lines-container {
    height: 430px
  }
}

@media screen and (max-width: 440px) { 
  .contact-container .lines-container {
    height: 180px
  }
}

@media screen and (max-width: 460px) {
  .contact-container .lines-container {
    margin-left: 85px;
  }

  .contact-text-block {
    margin-left: 188px;
  }
}

@media screen and (max-width: 417px) {
  .contact-text-claim {
    line-height: 18px;
  }

  .contact-button-wrapper .button-container {
    height: 26px;
    font-size: 9px;
    line-height: 9px;
    padding: 7px 12px;
  }
}

@media screen and (max-width: 390px) {
  .contact-text-block {
    margin-left: 180px;
  }
}