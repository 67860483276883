.nav-container {
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: space-between;
  height: 99px;
  padding: 0 var(--spacing-medium);
  top: 0;
  left: auto;
  right: auto;
  background-color: white;
  z-index: 9990;
}

.nav-links-container {
  list-style: none;
}

.logo-lines-container {
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
}

.logo-lines-container header {
    margin-right: 82px;
}

.logo-lines-short-rectangle-container {
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin-left: 245px;
}

.logo-lines-short-rectangle-container-invisible {
    display: none;
}

.nav-button-container {
  display: none;
  z-index: 9999;
  background: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}



/*Responsive*/

@media screen and (max-width: 1016px) {
  .logo-lines-container .lines-container {
    margin-left: -70px;
  }
}

@media screen and (max-width: 920px) {
    .logo-lines-short-rectangle-container  {
      display: none;
    }
}

@media screen and (max-width: 700px) {
  /* Lines correction */
  .logo-lines-container .lines-container {
    margin-left: 0px;
  }

  .nav-links-container-hide {
    display: none !important;
  }
  
  .nav-links-container-show {
    display: flex;
  }
  .nav-button-container {
    display: block;
  }

  .nav-links-container {
    display: flex;
    position: fixed;
    flex-direction: column;
    width: 100%;
    top: -20px;
    left: 0;
    padding: 39px 0;
    list-style: none;
    background-color: var(--color-black);
    color: var(--color-white);
  }

  .nav-links-container .navlink-container {
    display: flex;
    margin: 0 auto;
  }

  .nav-links-container .navlink-container:last-of-type {
    display: flex;
    margin: 0 auto;
  }

  .nav-links-container .navlink-container span { 
    color: var(--color-white);
  }

  .nav-links-container .navlink-container > span:hover {
    color: var(--color-white) !important;
    font-weight: 800 !important;
  }
  
  .nav-links-container .active {
    color: var(--color-white) !important;
    font-weight: 800 !important;
  }

  /* Illustrations */

  .logo-lines-container header {
    margin-right: 12px;
}
}

@media screen and (max-width: 460px) {
  .nav-container { 
    padding: 0 var(--spacing-small);
  }
}