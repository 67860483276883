.view-other-projects-container {
  background-color: var(--color-pale-background);
  width: 100%;
  margin: 0;
  padding-top: 48px;
  padding-bottom: 36px;
}

.view-other-projects-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 var(--spacing-medium);
    padding-bottom: 48px;
    gap: 32px;
}