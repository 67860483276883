.externallink-container {
  display: flex;
  margin: 48px auto 0;
}

.externallink {
  padding-bottom: 3px;
  font-size: var(--font-size-l);
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}

.externallink:hover {
  border-bottom: 0;
  padding-bottom: 4px;
}

.externallink--pink {
  border-bottom: 1px solid var(--color-pink);
  color: var(--color-pink);
}

.externallink--blue {
  border-bottom: 1px solid var(--color-blue);
  color: var(--color-blue);
}

.externallink--red {
  border-bottom: 1px solid var(--color-red);
  color: var(--color-red);
}

.externallink--red-dark {
  border-bottom: 1px solid var(--color-red-dark);
  color: var(--color-red-dark);
}

.externallink-icn-container {
  display: flex;
  height: 24px;
}

.externallink-icn {
  margin-top: 2px;
  margin-left: 9px;
  object-fit: none;
}

/* Filter spec from: https://codepen.io/sosuke/pen/Pjoqqp */

.externallink-icn--pink {
  filter: invert(85%) sepia(55%) saturate(1878%) hue-rotate(289deg)
    brightness(83%) contrast(91%);
}

.externallink-icn--blue {
  filter: invert(32%) sepia(93%) saturate(1423%) hue-rotate(201deg)
    brightness(90%) contrast(93%);
}

.externallink-icn--red {
  filter: invert(38%) sepia(15%) saturate(2619%) hue-rotate(324deg)
    brightness(106%) contrast(88%);
}

.externallink-icn--red-dark {
  filter: invert(14%) sepia(41%) saturate(7491%) hue-rotate(346deg)
    brightness(99%) contrast(100%);
}

@media screen and (max-width: 480px) {
  .externallink {
    font-size: var(--font-size-medium);
  }

  .externallink-icn-container {
    display: flex;
    height: 19px;
  }

  .externallink-icn {
    margin-top: 6px;
    margin-left: 9px;
    object-fit: unset;
  }
}
