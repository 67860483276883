.button-container {
  display: flex;
  flex-direction: row;
  padding: 14px 24px;
  border: 2px solid var(--color-black);
  background: none;
  color: var(--color-black);
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  cursor: pointer;
}

.button-container:hover {
    background-color: var(--color-black);
    color: var(--color-white);
    transition: background-color 0.1s ease-out;
}