.bigstepper-container {
  display: flex;
  flex-direction: column;
}

.bigstepper-title {
  display: inline-flex;
  align-items: baseline;
  margin: 86px auto 46px;
  font-size: var(--font-size-title-web--m);
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

.bigstepper-process-container {
  display: flex;
  flex-direction: column;
}

.bigstepper-subtitle {
  font-size: var(--font-size-xl);
  font-weight: 700;
  text-transform: uppercase;
}

.bigstepper-process-container .bigstepper-subtitle {
  text-align: center;
}

.bigstepper-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bigstepper-item {
  margin-right: 48px;
  font-size: var(--font-size-l);
  font-weight: 500;
  text-transform: uppercase;
}

.bigstepper-item:last-of-type {
  margin-right: 0;
}

.bigstepper-description {
  width: 640px;
  max-width: 640px;
  margin: 24px auto;
  font-size: var(--font-size-medium);
  font-weight: 300;
  line-height: 24px;
}

@media screen and (max-width: 1302px) {
  .bigstepper-title {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .bigstepper-description {
    width: 100%;
    max-width: 450px;
  }
}

@media screen and (max-width: 984px) {
  .bigstepper-list li {
    font-size: var(--font-size-medium);
  }
}

@media screen and (max-width: 900px) {
  .bigstepper-list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
  }

  .bigstepper-list li {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .bigstepper-description {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .bigstepper-title {
    margin-top: 48px;
    margin-bottom: 24px;
    font-size: var(--font-size-title-web--xs);
  }

  .bigstepper-title .dot-container-black {
    height: 9px;
    width: 9px;
  }
}

@media screen and (max-width: 483px) {
  .bigstepper-subtitle {
    font-size: var(--font-size-l);
  }

  .bigstepper-list li {
    font-size: var(--font-size-s);
  }

  .bigstepper-description {
    margin-top: 12px;
    font-size: var(--font-size-s);
  }
}
