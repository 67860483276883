.social-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  text-align: center;
  border: 1px solid var(--color-black);
  border-radius: 50%;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.social-button-container:hover {
  background-color: var(--color-black);
}

.social-button-container a {
  margin: 0;
  line-height: 0;
}

.social-button-container:hover a img {
  filter: invert(1);
}
